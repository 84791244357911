/* eslint-disable no-prototype-builtins */
import { makeActionCreator, resetReducer } from 'cooldux';

export const updateVitals = makeActionCreator('updateVitals');
export const resetVitals = makeActionCreator('resetVitals');

const initialState = {
  HR: '--',
  BR: '--',
  BP_SYS: '--',
  BP_DIA: '--',
  SPO2: '--',
  retakes: 0,
  pro_id: '',
  brOutOfRangeCount: 0,
  hrOutOfRangeCount: 0,
};

const vitalsMeasurements = resetReducer(initialState, (state = initialState, { type, payload }) => {
  switch (type) {
    case updateVitals.type: {
      return {
        ...state,
        ...payload
      };
    }
    case resetVitals.type: {
      return {
        ...state,
        HR: '--',
        BR: '--',
        BP_SYS: '--',
        BP_DIA: '--',
        SPO2: '--',
        retakes: state.retakes + 1,
      };
    }
    default:
      return state;
  }
});

export default vitalsMeasurements;
