import { combineReducers } from 'redux';

import PRO from './pro-forms';
import user from './user';
import errorScreens from './error-screens';
import vitals from './vitals';
import consent from './consent';

const { proForms, proSubmit } = PRO;

const reducers = combineReducers({
  errorScreens,
  proForms,
  proSubmit,
  user,
  vitals,
  consent,
});

export default reducers;
