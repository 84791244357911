import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import GeneralLayoutScrollListener from '../components/general-layout-scroll-listener';
import { updateVitals } from '../state/vitals';
import { forward } from './navigation';

const styles = {
  h1: {
    fontSize: 24,
    marginTop: 50,
    textAlign: 'center',
  },
  image: {
    maxWidth: 500,
    width: '100%',
    marginTop: 50,
  },
};

const Disclaimer = (props) => {
  const { classes, router, dispatch } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const detectScrolledToBottom = () => {
    setButtonDisabled(false);
  };

  const handleClick = () => {
    dispatch(updateVitals({ pro_id: Number(router.location.query.id) }));
    forward(router, props.location.query);
  }

  return (
    <GeneralLayoutScrollListener
      title="Disclaimer"
      buttonText="I UNDERSTAND"
      buttonOnClick={handleClick}
      buttonDisabled={buttonDisabled}
      onScroll={detectScrolledToBottom}
      router={router}
    >
      <img className={classes.image} src="/img/vital-trac.png" alt="Vital Trac Logo" />
      <h1 className={classes.h1}>DISCLAIMER</h1>
      <Typography>The Vital-Trac™ application is designed strictly for general health and wellness education and engagement. It is not cleared by the U.S. Food and Drug Administration (FDA). It's crucial to understand that Vital-Trac™ should not be used as a replacement for the professional medical advice or clinical judgment of your healthcare provider.</Typography>
      <Typography>Vital-Trac™ does not have the capabilities to diagnose, treat, alleviate, or prevent any form of disease, symptom, disorder, or abnormal physical state. If you suspect that you may be dealing with a potential health issue, we highly recommend that you consult with your healthcare provider or seek immediate assistance from emergency medical services.</Typography>
    </GeneralLayoutScrollListener>
  )
};

Disclaimer.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(withStyles(styles)(Disclaimer));
