import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { connect } from 'react-redux';

import GeneralLayout from './general-layout'; // Import the GeneralLayout component
import notificationTracks from '../notification-pages';
import buildTrackFromBundledEvents from '../lib/build-track-from-bundled-events';
import config from '../config';
import { addProInNewTabListener, emitProInNewTab } from '../lib/pro-in-new-tab';
import Logo from './logo';
import { colors } from '../lib/styles';
import Page from '../pages/page';

class NotificationIntro extends Page {
  constructor(props) {
    super(props);

    emitProInNewTab();
    addProInNewTabListener();
  }

  render() {
    const { type, id, router, notificationMeta } = this.props;
    
    return (
      <GeneralLayout
        hidden
        buttonText="START"
        buttonOnClick={() => {
          const { notification_id, dob } = this.props;
          if (type !== 'BUNDLED_EVENT') {
            return this.setTrack(`notification-${type}`, { id, type }, { ...notificationMeta, notification_id });
          }
          const track = buildTrackFromBundledEvents(notificationMeta.bundled_events, notification_id, dob);
          if (!track.length) {
            return router.push(`/notification-success/BUNDLED_EVENT?notification_id=${notification_id}`);
          }
          if (track[0].redirect) window.location.replace(`${config.PATIENT_APP_URL}/notifications/${notification_id}?dob=${dob}`);
          this.setRailroad(track);
        }}
        buttonDisabled={false} // Disable or enable the button
      >
        <Logo />
        <div>
          <p>
            <span>Welcome to</span>{' '}
            <span style={{ fontWeight: 'bold', color: colors.red }}>Vital-Trac</span>
          </p>
          <p>We are an independent health and wellness group focused on putting more power into the hands of patients.</p>
        </div>
      </GeneralLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;

  const { id, type, clinic_id } = ownProps.location.state;
  const defaults = find(notificationTracks, { type });
  return {
    id,
    type,
    clinic_id,
    text: defaults.introBodyText,
    headerText: defaults.introHeaderText,
    notificationMeta: user.meta,
    notification_id: user.notification_id,
    dob: user.dob,
  };
}

export default connect(mapStateToProps, {})(NotificationIntro);
