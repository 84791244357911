/* eslint-disable no-prototype-builtins */
import { makeActionCreator, resetReducer } from 'cooldux';

export const updateConsent = makeActionCreator('updateConsent');
export const resetConsent = makeActionCreator('resetConsent');

const initialState = {
  consented: false,
  firstName: '',
  lastName: '',
};

const consent = resetReducer(initialState, (state = initialState, { type, payload }) => {
  switch (type) {
    case updateConsent.type: {
      return {
        ...state,
        ...payload
      };
    }
    case resetConsent.type: {
      return initialState;
    }
    default:
      return state;
  }
});

export default consent;
