
function buildTrackFromBundledEvents(events, notificationId, dob) {
  if (!events || !events.length) {
    throw new Error('Invalid Bundled Notification events!');
  }
  return events.reduce((all, e) => {
    const redirect = e.event_type !== 'VITAL-TRAC' ? dob : false;
    if (e.stepCompleted) {
      return all;
    }
    all.push({
      track: `notification-${e.event_type}`,
      query: {
        type: e.event_type,
        id: e.pro_submission_id || e.video_broadcast_id || e.voice_broadcast_id,
        notification_id: notificationId,
        redirect,
        railroad: true,
        audioSrc: e.audioSrc,
        videoSrc: e.videoSrc,
        video_broadcast_id: e.video_broadcast_id,
        voice_broadcast_id: e.voice_broadcast_id,
      },
    });

    return all;
  }, []);
}

export default buildTrackFromBundledEvents;
