import { muiVitalTracNextTheme } from '../themes/mindset/theme';
import * as vtStyles from '../themes/mindset/styles';

const brand = {
  name: 'vitaltrac',
  source_id: 1,
  nextTheme: muiVitalTracNextTheme,
  title: 'Vital Trac',
  styles: { ...vtStyles },
  loginLogo: 'vital-trac.png',
}

export default brand;
