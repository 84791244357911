import React from 'react';

import Disclaimer from './pages/disclaimer';
import Instructions from './pages/instructions';
import MeasureVitals from './pages/measure-vitals';
import Results from './pages/results';
import InformedConsent from './pages/informed-consent';

const styles = {
  endingMessage: {
    margin: '5px',
  },
};

const notificationTracks = [
  {
    type: 'BUNDLED_EVENT',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you review several items',
    loggedInSuccessText: 'Successful Patient Report!',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [], // This one has some special handling as it just combined the above but the empty array is still needed
  },
  {
    type: 'VITAL-TRAC',
    introHeaderText: 'Vital Trac',
    introBodyText: 'requests that you measure your vitals',
    loggedInSuccessText: (
      <div style={styles.endingMessage}>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div style={styles.endingMessage}>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      Disclaimer,
      // InformedConsent,
      Instructions,
      MeasureVitals,
      Results,
    ],
  },
];

export default notificationTracks;
