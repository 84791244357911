import React, { useState } from 'react';
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { updateConsent } from '../state/consent';
import { forward } from './navigation';
import GeneralLayout from '../components/general-layout';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
  },
  pageContainer: {
    flexGrow: 1,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: fontSizing.body,
    '& p': {
      marginBottom: 20,
      marginTop: 20,
    },
    '& h2': {
      fontSize: 32,
      fontWeight: 'bold',
      marginBottom: 20,
      marginTop: 20,
    },
    '& h2:not(:first-of-type)': {
      marginTop: 40,
    },
    '& h3': {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 20,
      marginTop: 20,
    }
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textCentered: {
    textAlign: 'center',
  },
  textBold: {
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  marginV: {
    marginBottom: 20,
    marginTop: 20,
  },
  introText: {
    fontSize: 16,
    fontStyle: 'italic',
    marginBottom: 20,
    marginTop: '50px !important',
  },
  buttonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 40,
    marginBottom: 30,
  },
  button: {
    background: '#d8d8d8',
    border: 'none',
    borderRadius: 5,
    flexGrow: 1,
    marginLeft: 10,
    paddingBottom: 15,
    paddingTop: 15,
    fontSize: fontSizing.body,
    fontFamily: 'Nexa',
  },
  yesButtonSelected: {
    background: colors.green,
    color: 'white',
  },
  noButtonSelected: {
    background: colors.red,
    color: 'white',
  },
  textFieldWrapper: {
    marginBottom: 20,
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid black',
    },
  },
  marginTop20: {
    marginTop: 20,
  },
  textFieldContainer: {
    height: 110,
    paddingBottom: 20,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
  },
  continueButton: {
    height: 50,
    borderRadius: 25,
    backgroundColor: colors.green,
    borderStyle: 'none',
    width: '100%',
    color: 'white',
    fontSize: fontSizing.body,
    maxWidth: desktopMaxWidth,
    '&:disabled': {
      backgroundColor: '#d8d8d8',
    },
  },
};

const InformedConsent = (props) => {
  const { classes, router, updateConsent, location } = props;
  const [consented, setConsented] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleClickContinue = () => {
    if (consented === 'Y') {
      updateConsent({ consented, firstName, lastName });
    }
    forward(router, location.query);
  };

  const textFieldContainerStyle = !(consented === 'Y') ? { visibility: 'hidden' } : {};

  return (
    <GeneralLayout title="Instructions" buttonText="START" buttonOnClick={handleClickContinue}>
      <div className={classes.pageContent}>
        <p className={classes.introText}>With access to high-resolution raw data, our goal is to make biosensor data as accessible for researchers as possible. By enabling researchers to use independent device control, we want to remove any possible biases and help advance health research fields in a sustainable manner.</p>
        <h2>Vital-Trac Health Research Studies</h2>
        <p>This Privacy Policy summarizes the information we may collect or receive in connection with your use of any of Vital-Track and participation in any of our healthcare research studies (each a “Study” and collectively “Studies”).</p>
        <h3>Informed Consent</h3>
        <p>To participate in any Studies, you must first review and sign an informed consent and authorization form (if applicable) ("Informed Consent") for the Study. By using our App, you consent to the collection, use, and sharing of your information as described in this Privacy Policy and as outlined in the Informed Consent. To the extent anything in this Privacy Policy conflicts with the Informed Consent, the terms of the Informed Consent will control.</p>
        <p>Throughout this policy we may use the term "Study Team" to refer to all the entities that may have access to data collected from or about you through the App or as part of the Studies. For all Studies the Informed Consent will include specific information about the members of the Study Team.</p>
        <h3>Collection and Use of Information</h3>
        <p>Personal information is data that can be used to identify or contact a single person. Certain personal information and non-personal information, as described below, may be collected from or about you through the App or as part of the Studies.</p>
        <p>Vital-Trac and its affiliates, and the other Study Team members, may share the information we collect through the App with each other and use it consistent with this Privacy Policy.</p>
        <p>If you decide to participate in the Study, below are examples of the types of information that we may collect and how we may use it.</p>
        <h3>What information we collect</h3>
        <p>When you use our App and enroll in one of the Studies, below are examples of the categories of information (which may include some personal information) that may be collected from or about you through the App ("Study Data"). The specific categories of information that will be collected about you as part of a Study will be described in the Informed Consent.</p>
        <ul>
          <li><span className={classes.textBold}>Contact information</span> such as your name, email address,  and phone number.</li>
          <li><span className={classes.textBold}>Demographic information</span>, such as your age, gender, state of residence, and race.</li>
          <li><span className={classes.textBold}>Medical history and information</span>, such as your height/weight, prior medical diagnosis and testing (e.g., diagnosis of an irregular heart rhythm), current and previous use of certain medications (e.g., blood thinning medications), certain family history (e.g., history of atrial fibrillation) and health habits (e.g., smoking). This information may be collected through in-app surveys or other health surveys that you may be asked to complete.</li>
          <li><span className={classes.textBold}>Sensor information</span>, such as heart rate and beat to beat calculations.</li>
          <li><span className={classes.textBold}>Adverse event information</span>, which in many cases is non-personal information, i.e., data in a form that does not, on its own, permit direct association with any specific individual. Technical data includes, for example, information about your app usage (e.g., when an App was first launched), app version and installation ID, device identifier, and technical data about your device, such as operating system and model. Vital-Trac does not track its customers over time and across third party websites to provide targeted advertising and therefore does not respond to “Do Not Track” (DNT) signals.</li>
          <li><span className={classes.textBold}>Adverse event information</span>, such as a concern, adverse event, or other reportable matter arising in a Study.</li>
          <li><span className={classes.textBold}>Other information</span>, if any, described in the Informed Consent.</li>
        </ul>
        <p>Your Contact information will be separated from the rest of the data collected from you through the App and as part of the Studies and replaced with a random code ("Coded Study Data") before it is received on Vital-Trac systems.  When Vital-trac acts as the study sponsor, it is obligated to put in place a process that could include the review of non-Coded Study Data as needed to comply with law in certain jurisdictions or to fulfill Vital-Trac’s obligations as sponsor. Vital-Trac hires a third party to fulfill such obligations on its behalf and only Coded Study Data is received on Vital-Trac systems. For certain studies, if you experience a technical issue and consent to be transferred to Vital-Trac for additional technical support, Vital-Trac may have  access to certain information that directly identifies you. Other entities involved in the Study, such as the Study principal investigator or other Study Team members, may maintain Study Data in an identifiable form.</p>
        <h3>How we use your information</h3>
        <p>Your personal information, such as your contact information, if collected through the App, may be used for purposes described in the Informed Consent including:</p>
        <ul>
          <li>To enroll you in the Study, including determining your eligibility for participation in the Study</li>
          <li>To conduct and support the Study</li>
          <li>To contact you with surveys or other messages related to the Study through in-app notifications, email, or otherwise</li>
        </ul>
        <p>Your Coded Study Data may be used for purposes described in the Informed Consent including:</p>
        <ul>
          <li>To conduct and support the Study</li>
          <li>To develop health-related products and improvement activities</li>
          <li>For other research studies, if permitted in the Informed Consent</li>
          <li>To issue research findings and related reports, which will not identify you</li>
        </ul>
        <p>Your technical data (as described above) may also be used to determine your eligibility for a Study and general usage of the Apps.</p>
        <h2>Disclosure to Third Parties</h2>
        <h3>Service Providers</h3>
        <p>Vital-Trac and the other Study Team members may share your information with companies who provide services for or on behalf of the Study such as the independent third party institutional review board responsible for reviewing the Study and protecting your rights as a research participant, or third parties that have been hired to perform Study-related services, such as receiving and processing Study-related complaints, on our behalf. These companies are obligated to protect your information.</p>
        <h3>Others</h3>
        <p>Your personal information and Coded Study Data may also be disclosed to the following third parties:</p>
        <ul>
          <li><span className={classes.textBold}>Government and Regulatory authorities</span>, such as the U.S. Department of Health and Human Services, the Food and Drug Administration, and other federal or state government agencies.</li>
          <li><span className={classes.textBold}>Law enforcement or other third parties</span> pursuant to valid legal process such as a subpoena, litigation or court order. We may also disclose information about you if we determine that for purposes of national security, or other issues of public importance, disclosure is necessary or appropriate.</li>
          <li><span className={classes.textBold}>Other Approved Study Researchers</span>, if permitted by the Informed Consent certain third-party researchers who are approved may access limited Study Data. The categories of approved study researchers, the type of Study Data they may have access to, and the purposes that they may use the data for will be described in more detail in the Informed Consent.</li>
          <li><span className={classes.textBold}>Others</span>, if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. We disclose information as described in the Informed Consent. Additionally, in the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party.</li>
        </ul>
        <h2>Protection of Your Information</h2>
        <p>Vital-Trac takes the security of your personal information very seriously. Vital-Trac has implemented reasonable measures to keep the Study Data it maintains safe, and to protect the confidentiality of your information, including storing it in systems with limited access housed in facilities using physical security measures. Despite our security measures, total confidentiality cannot be guaranteed.</p>
        <h2>Retention of Personal Information</h2>
        <p>Your participation in any Study is voluntary. You may decide not to participate or you may leave any Study at any time. If you decide to withdraw, we may not delete the information that we have already collected and we may continue to use it, but we will stop receiving any new Study Data about you and will stop contacting you about the Study except in the case of serious, actionable medical need. The specific retention policies and the steps you need to take to withdraw will be described in the Informed Consent for each Study.</p>
        <h2>Children</h2>
        <p>Our Apps are not intended for children under the age of 18 or equivalent minimum age in the relevant jurisdiction. If we learn that we have collected the personal information of a child under 18, we will take steps to delete the information as soon as possible.</p>
        <h2>Our Company wide Commitment to Your Privacy</h2>
        <p>To make sure your personal information is secure, we communicate our privacy and security guidelines to Vital-Trac employees and strictly enforce privacy safeguards within the company.</p>
        <h2>Privacy Questions</h2>
        <p>If you have any questions or concerns about this Privacy Policy please contact us. If you have questions about a Study please contact a Study Team member using the contact information listed in the Informed Consent. To withdraw from the Study, please follow the specific instructions in the Informed Consent. Please note that deleting the App will not withdraw you from the Study.</p>
        <p>Vital-Trac may update this Privacy Policy from time to time. If we change the policy in a material way, notice will be provided to you either in the App or through a Study-specific email along with the updated Privacy Policy. We may also provide a notice on any Study-specific website.</p>
        <div className={classes.answerButtonContainer}>
          <button
            onClick={() => setConsented('Y')}
            className={classes.button}
            type="button"
            style={consented === 'Y' ? styles.yesButtonSelected : {}}
          >
            Yes, I'll Share
          </button>
          <button
            onClick={() => setConsented('N')}
            className={classes.button}
            type="button"
            style={consented === 'N' ? styles.noButtonSelected : {}}
          >
            No Thanks
          </button>
        </div>
        <div className={classes.textFieldContainer} style={textFieldContainerStyle}>
          <div className={classes.textFieldWrapper}>
            <TextField
              placeholder="First Name"
              value={firstName}
              onChange={(e) => { setFirstName(e.target.value); }}
              name="firstName"
              variant="standard"
              fullWidth
              inputProps={{ style: { fontSize: fontSizing.body, fontFamily: 'Nexa' } }}
            />
          </div>
          <div className={`${classes.textFieldWrapper} ${classes.marginTop20}`}>
            <TextField
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => { setLastName(e.target.value); }}
              name="lastName"
              variant="standard"
              fullWidth
              inputProps={{ style: { fontSize: fontSizing.body, fontFamily: 'Nexa' } }}
            />
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};

InformedConsent.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default connect(null, { updateConsent })(withStyles(styles)(InformedConsent));
