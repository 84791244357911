import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  logoContainer: {
    height: 120,
    textAlign: 'center',
    maxWidth: '300px',
    margin: 'auto',
  },
  logo: {
    maxWidth: '300px',
  }
};

const Logo = ({ classes }) => (
  <div className={classes.logoContainer}>
    <img className={classes.logo} src="/img/vital-trac.png" alt="Vital Trac Logo" />
  </div>
);

export default withStyles(styles)(Logo);
