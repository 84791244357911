import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import AppBar from '../components/app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { BookInfoOpenReaderSolid } from '../lib/icons';

const styles = {
  arrowIcons: {
    verticalAlign: 'bottom',
  },
  bookInfoWrapper: {
    float: 'left',
    height: 30,
    marginRight: 10,
    width: 30,
  },
  clearFix: {
    '&::after': {
      clear: 'both',
      content: "''",
      display: 'block',
    },
  },
  flexRow: {
    display: 'flex',
  },
  industrySolidWrapper: {
    marginRight: 10,
    width: 30,
  },
  instructionIcon: {
    marginBottom: 10,
    width: 50,
  },
  instructionImages: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
  },
  instructionImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  noLineWrap: {
    whiteSpace: 'nowrap',
  },
  paddingLeft20: {
    paddingLeft: 20,
  },
  pageContent: {
    fontSize: fontSizing.body,
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
    '& section p:first-of-type': {
      marginTop: 0,
    },
    '& ul': {
      marginTop: 0,
    },
    '& ol': {
      marginTop: 0,
    },
  },
  pageTitle: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
    paddingBottom: 20,
  },
  phoneImage: {
    marginBottom: 20,
    marginTop: 20,
    width: 300,
  },
  phoneImageWidth: {
    width: 300,
  },
  redFill: {
    '& path': {
      fill: colors.errorRed,
    },
  },
  redText: {
    color: colors.errorRed,
  },
  sectionHeader: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 10,
  },
  subsectionHeader: {
    fontSize: fontSizing.body,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: 20,
  },
  table: {
    '& td': {
      fontSize: fontSizing.body,
    },
    '& th': {
      fontSize: fontSizing.body,
      lineHeight: 1.43,
    },
  },
  tableIcon: {
    maxWidth: 100,
  },
  tableImage: {
    maxWidth: 150,
  },
  tableOfContentsList: {
    listStyleType: 'none',
    '& li': {
      marginBottom: 10,
    },
  },
  tableCell: {
    verticalAlign: 'top',
  },
  marginBottom20: {
    marginBottom: 20,
  },
};

const linkStyles = {
  root: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

const Link = (props) => {
  const {
    classes,
    router,
    hash,
    children,
  } = props;

  const goToSection = () => {
    router.push(`${router.location.pathname}${hash}`);
    window.requestAnimationFrame(() => {
      document.querySelector(hash).scrollIntoView();
    });
  };
// TODO This patches anchor links not working? Lets try to remove this when we update react or redux next?
  return (
    <span
      role="link"
      tabIndex={0}
      onKeyPress={(event) => { if (event.key === 'Enter') goToSection(); }}
      onClick={goToSection}
      className={classes.root}
    >
      {children}
    </span>
  );
};

Link.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  hash: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledLink = withStyles(linkStyles)(Link);

class CoreVitalUserManual extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <h1 className={classes.pageTitle}>
            <div>Informed Vital Core</div>
            <div>Instructions for Use</div>
            <div>Non-invasive Spot Measurement of Pulse Rate</div>
            <p>Mindset Medical</p>
          </h1>
          <p>This manual provides instructions for the operation of the Informed Vital Core Application (IVC App) for the measurement of Pulse Rate.</p>
          <p>
            <div>May 14, 2024</div>
            <div>Version 2.0</div>
          </p>
          <h2 className={classes.sectionHeader}>
            Table of Contents
          </h2>
          <ol className={classes.tableOfContentsList}>
            <li>
              <StyledLink
                router={router}
                hash="#s1-0"
              >
                1. READ FIRST
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s2-0"
              >
                2. WHAT IS IVC APP?
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s3-0"
              >
                3. HOW IT WORKS?
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s4-0"
              >
                4. GENERAL INFORMATION
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s5-0"
              >
                5. INTRODUCTION
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s6-0"
              >
                6. PRESCRIPTION STATEMENT
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s7-0"
              >
                7. INTENDED USE
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s8-0"
              >
                8. INDICATIONS FOR USE
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s9-0"
              >
                9. IVC APP USE ENVIRONMENT
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s10-0"
              >
                10. KEY FEATURES AND APPLICATION REQUIREMENTS
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s10-1"
              >
                10.1 DEVICES AND OPERATING SYSTEMS COMPATABILITY
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <div className={classes.paddingLeft20}>
                <StyledLink
                  router={router}
                  hash="#s10-1-1"
                >
                  10.1.1 CAMERA REQUIREMENTS
                </StyledLink>
              </div>
            </li>
            <li className={classes.paddingLeft20}>
              <div className={classes.paddingLeft20}>
                <StyledLink
                  router={router}
                  hash="#s10-1-2"
                >
                  10.1.2 COMPATIBLE BROWSERS
                </StyledLink>
              </div>
            </li>
            <li className={classes.paddingLeft20}>
              <div className={classes.paddingLeft20}>
                <StyledLink
                  router={router}
                  hash="#s10-1-3"
                >
                  10.1.3 SUPPORTED OPERATING SYSTEMS
                </StyledLink>
              </div>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s11-0"
              >
                11. YOUR ROLE IN HEART HEALTH MONITORING WITH THE IVC APP
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s12-0"
              >
                12. SAFETY CONSIDERATIONS
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s12-1"
              >
                12.1 BEFORE USING INFORMED VITAL CORE
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s12-2"
              >
                12.2 CONTRAINDICATIONS
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s12-3"
              >
                12.3 WARNINGS
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s12-4"
              >
                12.4 CAUTIONS
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s12-5"
              >
                12.5 USING INFORMED VITAL CORE SECURELY AND CYBERSECURITY AWARENESS
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s13-0"
              >
                13. INFORMED VITAL CORE APPLICATION OVERVIEW
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s13-1"
              >
                13.1 IMPORTANT ITEMS TO REMEMBER WHEN USING THE INFORMED VITAL CORE APPLICATION
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s13-2"
              >
                13.2 PREPARING INFORMED VITAL CORE FOR USE
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s13-3"
              >
                13.3 CONNECTING INFORMED VITAL CORE APPLICATION
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s13-4"
              >
                13.4 APPLICATION CONTROLS/NAVIGATION
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s14-0"
              >
                14. TROUBLESHOOTING GUIDE
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s14-1"
              >
                14.1 COMMON IVC APP ERRORS
              </StyledLink>
            </li>
            <li className={classes.paddingLeft20}>
              <StyledLink
                router={router}
                hash="#s14-2"
              >
                14.2 IVC APPLICATION ERROR MESSAGES
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s15-0"
              >
                15. CUSTOMER SUPPORT AND CONTACT INFORMATION
              </StyledLink>
            </li>
            <li>
              <StyledLink
                router={router}
                hash="#s16-0"
              >
                16. MANUFACTURER
              </StyledLink>
            </li>
          </ol>
          <section id="s1-0">
            <h2 className={classes.sectionHeader}>1. Read First</h2>
            <p>For the best experience and safety, please read this guide completely before use.</p>
          </section>
          <section id="s2-0">
            <h2 className={classes.sectionHeader}>2. What Is IVC App?</h2>
            <p>The Informed Vital Core Application (IVC App) is an innovative web application designed for easy pulse rate measuring.   Prescribed by your healthcare provider, it's perfect for indoor use with standard lighting.  The IVC App is not a traditional application and is not available through the Apple App Store or Google Play Store.  Your healthcare provider will send it directly to you through your standard messaging application.  The IVC App works with the camera on your smartphone, tablet, or computer.  Simply click on the unique URL in the text message and the IVC App will automatically run in your default web browser.  Just follow the online instructions and you're all set.</p>
          </section>
          <section id="s3-0">
            <h2 className={classes.sectionHeader}>3. How It Works?</h2>
            <p>The IVC App measures your pulse rate by reviewing color changes in your face as your heart beats.  Make sure your face is well-lit, with no dark shadows or bright spots, for accurate results.</p>
          </section>
          <section id="s4-0">
            <h2 className={classes.sectionHeader}>4. General Information</h2>
            <p>This document contains instructions for the use of the IVC App and a technical description of this software application.</p>
          </section>
          <section id="s5-0">
            <h2 className={classes.sectionHeader}>5. Introduction</h2>
            <p>Just open the IVC App on your device.  No extra gadgets needed.  The app uses your device's camera to analyze the video of your face.  Each time your heart beats, your face shows tiny color changes. These are invisible to the eye but not to the IVC App.  The IVC App captures these color variations and calculates your pulse rate in real time.</p>
          </section>
          <section id="s6-0">
            <h2 className={classes.sectionHeader}>6. Prescription Statement</h2>
            <p>The IVC App is a health tool prescribed by healthcare providers in the USA.  Make sure to use it as recommended by your healthcare provider or healthcare provider.</p>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <div><strong><i>Read carefully before using the IVC App. Pay attention to the Safety Information and Warnings throughout the manual.</i></strong></div>
            </div>
            <p><strong><i>Caution: Federal law restricts this software application for sale by or on the order of a physician.  The IVC App may only be used as prescribed by your healthcare provider and is not intended to be the sole method of checking your pulse rate.</i></strong></p>
          </section>
          <section id="s7-0">
            <h2 className={classes.sectionHeader}>7. Intended Use</h2>
            <p>The Informed Vital Core Application is intended for non-invasive spot measurement of pulse rate of adult patients in home use, hospitals, clinics, and long-term care settings.</p>
          </section>
          <section id="s8-0">
            <h2 className={classes.sectionHeader}>8. Indications for Use</h2>
            <p>The Informed Vital Core Application is intended for non-invasive spot measurements of pulse rate.</p>
            <p>The Informed Vital Core Application is indicated for use on adults, 22 years of age or older, who do not require critical care or continuous vital signs monitoring.</p>
            <p>The Informed Vital Core Application is not intended to independently direct therapy.</p>
          </section>
          <section id="s9-0">
            <h2 className={classes.sectionHeader}>9. IVC App Use Environment</h2>
            <p><strong>Use it Inside:</strong> Whether you're relaxing at home, in a healthcare facility, or any indoor setting, the IVC App is designed to fit into your lifestyle seamlessly.</p>
            <p><strong>Lighting Matters:</strong> For the best results: <strong>Ensure your face is evenly lit.</strong> Avoid using it in areas with dark shadows or with overly bright spots falling across your face.  This helps the IVC App measure your heartbeat through the detection of small changes in your facial coloration.</p>
            <p><strong>Be still:</strong> Make sure your device is in a stable position.  You may place your phone in a stand, prop the device against a stable surface, or use your elbows to stabilize your arms.  If using a tablet, laptop, or desktop computer, simply sit in front of the camera.</p>
            <p><strong>Guide Box:</strong> Once you are seated place your face fully within the guide box and center the “+” sign on your nose.  Don't sit any farther than an arm's length away from the camera.  Press the <strong>START</strong> button to begin.  If the guide box turns red, follow the on-screen prompts.</p>
          </section>
          <section id="s10-0">
            <h2 className={classes.sectionHeader}>10. Key Features and Application Requirements</h2>
            <p>You don't need any extra gadgets – if you have a smartphone, tablet, or computer with a camera, text messaging, and Internet (Wi-Fi or cellular), you're all set.</p>
            <p><strong>Easy and Accessible for Everyone:</strong> No complex setup or special training needed.  It's straightforward and user-friendly. The IVC App needs to see your face for a full minute to measure your pulse rate.</p>
            <p>In case you see the green box around your face turn red: Don't worry.  This just means your face needs better lighting or you need to stay still.  Just follow the prompts.</p>
            <p>If you see "Unable to Measure Vitals", let your healthcare provider know that the IVC App may not work for you with your current device.</p>
            <p>
              <div><strong>Minimum Requirements for Your Device:</strong></div>
              <div>Resolution: 640 x 480 pixels or higher.</div>
              <div>Speed: 20 frames per second (fps) or faster.</div>
              <div>Camera Access: Your device's operating system must allow camera use.</div>
              <div>Memory: At least 1.5 GB RAM.</div>
              <div>Browser: Chrome, Edge, Firefox, or Safari.</div>
            </p>
            <p>
              <div><strong>Checking Your Device's Compatibility:</strong></div>
              <div>For iOS/iPad OS: Go to Settings &gt; Camera &gt; Record Video. Ensure it's set to at least 720p HD at 30 fps.</div>
              <div>For Android: Go to Settings &gt; Gear icon next to Camera &gt; Front picture size. Look for a minimum setting of 2448x2448.</div>
            </p>
            <p><strong>Seamless Connection with Your Healthcare Provider:</strong> Once your pulse rate is measured, the IVC App securely sends the data to your healthcare provider, keeping them informed.</p>
            <p><strong>Good to Know:</strong> Most modern smartphones, tablets, and computers exceed these requirements, so you're likely ready to go.</p>
          </section>
          <section id="s10-1">
            <h2 className={classes.sectionHeader}>10.1 Devices and Operating Systems Compatibility</h2>
          </section>
          <section id="s10-1-1">
            <h2 className={classes.sectionHeader}>10.1.1 Camera Requirements</h2>
            <div><strong>What you Need:</strong> Any camera-enabled device.</div>
            <div><strong>Specifications:</strong> Must record video at over 20 frames per second with a minimum resolution of 640 x 480 pixels.</div>
          </section>
          <section id="s10-1-2">
            <h2 className={classes.sectionHeader}>10.1.2 Compatible Browsers</h2>
            <p><strong>Use the Right Browser:</strong> Check this table to see if your browser can run the IVC App.</p>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Browser</TableCell>
                  <TableCell>Compatablility</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Edge
                  </TableCell>
                  <TableCell>
                    Version 79 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Firefox
                  </TableCell>
                  <TableCell>
                    Version 52 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Chrome
                  </TableCell>
                  <TableCell>
                    Version 57 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Safari (Mac OS)
                  </TableCell>
                  <TableCell>
                    Version 11 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Safari (iOS/iPad OS)
                  </TableCell>
                  <TableCell>
                    Version 11 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Chrome (Android)
                  </TableCell>
                  <TableCell>
                    Version 98 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Edge (Android)
                  </TableCell>
                  <TableCell>
                    Version 99 and higher
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Firefox (Android)
                  </TableCell>
                  <TableCell>
                    Version 96 and higher
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </section>
          <section id="s10-1-3">
            <h2 className={classes.sectionHeader}>10.1.3 Supported Operating Systems</h2>
            <p><strong>Check Your OS:</strong> The IVC App is tested and works well with the following operating systems:</p>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Operating System (OS)</TableCell>
                  <TableCell>Compatibility</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>iOS</TableCell>
                  <TableCell>Version 14.8 and higher</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Android OS</TableCell>
                  <TableCell>Version 11 and higher</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>iPad OS</TableCell>
                  <TableCell>Version 14.8 and higher</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MacOS</TableCell>
                  <TableCell>Version 10.15 (Catalina) and higher</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Windows OS</TableCell>
                  <TableCell>Version 10 and higher</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p><strong><i>Please note: Some devices running on these operating systems may not be compatible with the IVC App. If you have any technical difficulties, please contact <a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com</a>.</i></strong></p>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <div><strong><i>The IVC App may not work on all camera-equipped smartphones, tablets, or computers.  If the IVC App doesn't work on your device seek an alternative method to measure your pulse rate.</i></strong></div>
            </div>
          </section>
          <section id="s11-0">
            <h2 className={classes.sectionHeader}>11. Your Role in Heart Health Monitoring with the IVC App</h2>
            <p><strong>Getting the best from your IVC App:</strong> Remember, use the IVC App as instructed here; it's a part of your health journey, not the whole story.  Your healthcare provider is your best resource when it comes to interpreting the readings.</p>
            <p>Follow These Simple Tips for Reliable Readings:</p>
            <ol>
              <li><strong>Not a Standalone Diagnosis Tool:</strong> Always consult your healthcare provider for clinical decisions.</li>
              <li><strong>Indoor Use Only:</strong> The IVC App is designed for indoor environments only.</li>
              <li><strong>Stay Still and Well-Lit:</strong> Keep your head still and ensure even lighting on your face.</li>
              <li><strong>Background Matters:</strong> Keep it calm and still.  No moving objects like active TVs, people, or pets in the background.</li>
              <li><strong>Steady Lighting is Key:</strong> Avoid places with rapidly changing light on your face.</li>
              <li><strong>Full Face Visibility:</strong> Make sure the IVC App can see your forehead, cheeks, and chin clearly.</li>
              <li><strong>Face Makeup and Sunscreen:</strong> Avoid wearing these when measuring your pulse rate.</li>
              <li><strong>Relax:</strong> Sit relaxed for five minutes before using the IVC App. Keep your feet flat on the floor, back supported.</li>
              <li><strong>Keep Your Device Charged:</strong> Ensure your phone, tablet, or computer has enough battery for the entire process.</li>
              <li><strong>Internet Connection is Crucial:</strong> The IVC App needs a stable Internet connection to work and send readings.</li>
            </ol>
            <p>By following these simple steps, you'll ensure you get pulse rate readings for you and your healthcare provider.</p>
          </section>
          <section id="s12-0">
            <div className={classes.sectionHeader}>12. Safety Considerations</div>
            <p><strong>Symbols Guide:</strong> Your IVC App and its instructions come with specific symbols. Familiarize yourself with these.  You'll find these symbols in the app and throughout this guide. They are here to help you navigate and understand the app better.</p>
            <p><strong>Always Consult the Experts:</strong> For interpretations and advice, always reach out to your healthcare provider.</p>
            <p>Remember, the IVC App is a monitoring tool, not a healthcare provider.</p>
            <p>Always follow the guidance of your healthcare provider or licensed healthcare provider. They know the best methods for handling your health.</p>
          </section>
          <section id="s12-1">
            <div className={classes.sectionHeader}>12.1 Before Using Informed Vital Core</div>
            <p>Carefully read these instructions for use and any information provided by your healthcare provider.</p>
          </section>
          <section id="s12-2">
            <h2 className={classes.sectionHeader}>12.2 Contraindications</h2>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <p>The following facial conditions are contraindicated with the IVC App and may impact the ability to measure your pulse rate.  Check with your healthcare provider if you have any of the following:</p>
            </div>
            <div><strong>Conditions:</strong></div>
            <ul>
              <li>Facial scars.</li>
              <li>Facial burns.</li>
              <li>Port wine stains or large birth marks on your face.</li>
              <li>Facial tattoos or hennas.</li>
              <li>Rosacea, Eczema, rashes on your face.</li>
              <li>Facial bruising.</li>
            </ul>
          </section>
          <section id="s12-3">
            <h2 className={classes.sectionHeader}>12.3 Warnings</h2>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <div><strong>Know the Do's and Don'ts:</strong></div>
            </div>
            <ol>
              <li><strong>Prescription Device:</strong> Use the IVC App only as directed by your healthcare provider in the U.S.</li>
              <li><strong>Part of a Bigger Picture:</strong> Don't rely solely on the app for assessing your physical health.</li>
              <li><strong>Healthcare provider's Role:</strong> Trust your healthcare provider to make decisions using the IVC App's data.</li>
              <li><strong>Understand the App:</strong> Discuss it with your healthcare provider to grasp the IVC App's features and instructions.</li>
              <li><strong>Internet Connection:</strong> Ensure a stable Internet connection for the IVC App to function and transmit results.</li>
              <li><strong>Avoid Distractions:</strong> Don't use the IVC App while driving, walking, or during any activity that needs your full attention.</li>
              <li><strong>Face Makeup and Sunscreen:</strong> Avoid wearing these when measuring your pulse rate.</li>
              <li><strong>Prepare to Measure:</strong> Sit comfortably for five minutes before using the app, keep your feet flat, and your back supported.</li>
              <li><strong>Lighting Matters:</strong> Ensure even lighting on your face.</li>
              <li><strong>Steady Light Conditions:</strong> Avoid rapidly changing light environments.</li>
              <li><strong>Stay Still and Focused:</strong> Keep your face still and follow the on-screen guide during measurement. Use the IVC App away from vibrations or movements.</li>
              <li><strong>Proper Use:</strong> Not following instructions could result in no measurement.</li>
              <li><strong>Single App Use:</strong> Don't run other apps simultaneously with the IVC App.</li>
              <li><strong>Camera Access:</strong> Make sure the IVC App has permission to use your device's camera.</li>
              <li><strong>Avoid Camera Competition:</strong> Don't use other camera-interfacing apps (like social media or video conferencing apps) while using the IVC App.</li>
              <li><strong>Session Timeout:</strong> Be active in the IVC App; it times out after 5 minutes of inactivity.  You'll need to restart it by re-entering your date of birth if it times out.</li>
              <li><strong>No Sharing:</strong> The IVC App is for your use only.  Don't share the app with someone else.</li>
            </ol>
          </section>
          <section id="s12-4">
            <h2 className={classes.sectionHeader}>12.4 Cautions</h2>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <p>Keep these in mind for safe use:</p>
            </div>
            <ol>
              <li><strong>Stay Still, Stay Lit:</strong> Avoid large movements and ensure good lighting. The IVC App will alert you if these aren't optimal.</li>
              <li><strong>Camera Condition:</strong> A cracked or dirty camera lens on your device can obstruct measurement.</li>
              <li><strong>Quiet Time:</strong> Don't eat, drink, or talk while your pulse rate is being measured.</li>
              <li><strong>Notifications Setup:</strong> Set your device to receive links from your healthcare provider to use the IVC App.</li>
              <li><strong>Indoor Use:</strong> Use the IVC App indoors only.</li>
              <li><strong>App Issues?</strong> If the IVC App isn't working as expected, stop using it and try again later.  If the IVC App doesn't work on your device, contact your healthcare provider for an alternative method to measure your pulse rate.</li>
              <li>
                <div><strong>The App Might Not Work If:</strong></div>
                <ol style={{ listStyleType: 'lower-alpha' }}>
                  <li>You have low blood flow, anemia, or other blood-related issues.</li>
                  <li>Your face isn't clearly visible to the camera.</li>
                  <li>You have significant scars, tattoos, or heavy makeup on your face.</li>
                  <li>Your face is overly bright.</li>
                  <li>You're moving, talking, eating, or controlling your breathing.</li>
                  <li>There are other movements in the camera view.</li>
                  <li>Another person or animal is in the camera view.</li>
                  <li>Water or other liquids are on the camera lens.</li>
                  <li>There are moving lights or shadows on or behind you.</li>
                  <li>You're in an environment with flashing or variable lights, like a TV.</li>
                  <li>There's mechanical vibration to you or the camera.</li>
                </ol>
              </li>
              <li><strong>Wait for Right Conditions:</strong> If you can't measure now, try again later under better conditions or use a different method.</li>
              <li><strong>No Movement:</strong> Stay still when measuring.</li>
              <li><strong>Not for Arrhythmias:</strong> The IVC App doesn't detect arrhythmias or irregular heartbeats.</li>
              <li><strong>Not Continuous Monitoring:</strong> It's not designed for ongoing vital signs monitoring.</li>
              <li><strong>Unstudied in Certain Conditions:</strong> The app hasn't been tested in people with movement disorders (such as Parkinson's, Multiple Sclerosis, Tardive Dyskinesia, etc.).  Consult your healthcare provider if unsure about your condition and its impact on the use of the IVC App.</li>
              <li><strong>No Unauthorized Tampering:</strong> Any unapproved changes to the IVC App's software may prevent it from working correctly.</li>
            </ol>
          </section>
          <section id="s12-5">
            <h2 className={classes.sectionHeader}>12.5 Using Informed Vital Core Securely and Cybersecurity Awareness</h2>
            <p>The IVC App connects to the Internet.  Like any online tool, it's important to stay vigilant against cyber threats. The IVC App has strong security measures, but your actions matter too.</p>
            <p>By following these guidelines, you're playing an important role in keeping your health data secure while using the IVC App.</p>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <p><strong>Your Checklist:</strong></p>
            </div>
            <ol>
              <li><strong>Link Awareness:</strong> Only click on links that are clearly from your healthcare provider for the IVC App.</li>
              <li><strong>Spotting Fakes:</strong> If a link takes you somewhere unexpected, don't enter any information. Instead, contact Mindset Medical at <a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com</a>.  (The IVC App uses <a href="https://ivc-app.mindsetmedical.com">https://ivc-app.mindsetmedical.com</a> and <a href="mailto:https://patient.mindsetmedical.com">https://patient.mindsetmedical.com</a>)</li>
              <li><strong>Personal Info Red Flags:</strong> The IVC App won't ask for your address, phone number, social security, insurance, or payment details.  Remember, your healthcare provider already has these.</li>
              <li><strong>Date of Birth Confirmation:</strong> The IVC App will ask for your birth date for identity verification.</li>
              <li><strong>Your Pulse Rate Sharing:</strong> You'll be asked to measure and send your pulse rate to your healthcare provider.</li>
              <li><strong>Encrypted Data:</strong> Your pulse rate data is encrypted for secure transmission.</li>
              <li><strong>Secure Internet is Best:</strong> Use a WPA2 encrypted Wi-Fi or your mobile data for a safer connection.</li>
              <li><strong>Avoid Public Wi-Fi:</strong> Don't use the IVC App on public Internet connections.</li>
              <li><strong>Device Security:</strong> Protect your device with face or fingerprint biometric, PINs, or passcodes.</li>
              <li><strong>Update Regularly:</strong> Keep your operating system and apps updated for top-notch security.</li>
              <li><strong>Privacy Matters:</strong> Use the IVC App in private settings to keep your health information confidential.</li>
              <li><strong>No Storage on Device:</strong> Your health data isn't stored on your device. Close the IVC App browser tab after submitting your pulse rate.</li>
              <li><strong>You're the Key:</strong> Remember, you're responsible for keeping your date of birth confidential.</li>
            </ol>
          </section>
          <section id="s13-0">
            <h2 className={classes.sectionHeader}>13. Informed Vital Core Application Overview</h2>
            <p><strong>No Downloads, No Hassle:</strong> The IVC App is a simple, web-based app that uses your device's camera to measure your pulse rate. No downloading apps, creating accounts, or remembering passwords needed.</p>
            <ol>
              <li><strong>Get Started Easily:</strong> Your healthcare provider sends you a unique link.  Just tap or click it.</li>
              <li><strong>Quick Setup:</strong> The link opens your device's default browser.  Enter your birth date, agree to the terms, and give your consent.</li>
              <li><strong>Camera Access:</strong> Permit the IVC App to use your device's camera.</li>
              <li><strong>Ready, Set, Position:</strong> Align your face within the on-screen guide box.</li>
              <li><strong>Hit START:</strong> Tap or click <strong>START</strong> to begin measuring your pulse rate.</li>
              <li><strong>Share Your Results with your Healthcare provider:</strong> After the measurement, you can easily submit it to your healthcare provider.</li>
            </ol>
          </section>
          <section id="s13-1">
            <h2 className={classes.sectionHeader}>13.1 Important Items to Remember when Using the Informed Vital Core Application</h2>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <p><strong>Key tips for successful use of the IVC App:</strong> Following these simple steps ensures a smooth experience with the IVC App.</p>
            </div>
            <ol>
              <li><strong>Indoor Use Only:</strong> The IVC App is designed for indoor environments.</li>
              <li><strong>Lighting is Key:</strong> Ensure your face is evenly lit for accurate readings.</li>
              <li><strong>Avoid Direct Bright Lights:</strong> Keep bright lights from shining directly on you or behind you.</li>
              <li><strong>No Shadows or Bright Spots:</strong> Aim for consistent lighting on your face.</li>
              <li><strong>Clear Face View:</strong> Remove any masks, hats, or coverings.  Glasses and/or facial hair are okay!</li>
              <li><strong>Stay Still:</strong> Don't eat, drink, or walk while using the IVC App.</li>
              <li><strong>Face Makeup and Sunscreen:</strong> Avoid wearing these products when measuring your pulse rate.</li>
              <li><strong>App Alerts:</strong> Heed any warnings about lighting or movement.</li>
              <li><strong>Pulse Rate Checks:</strong> If your pulse rate is under 40 or over 120 beats per minute, the IVC App will prompt a recheck.</li>
              <li><strong>Submit to Share:</strong> Tap <strong>'END'</strong> to send data to your healthcare provider. If you forget, they won't receive it.</li>
              <li><strong>Auto-Recovery Feature:</strong> The IVC App can handle brief pop-up notifications.</li>
              <li><strong>Positioning Matters:</strong> For best results, align your face within the guide box.</li>
            </ol>
          </section>
          <section id="s13-2">
            <h2 className={classes.sectionHeader}>13.2 Preparing Informed Vital Core for Use</h2>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <p><strong>Get comfortable and ready for vitals measurement:</strong> Taking these steps helps ensure that the IVC App can measure your vitals:</p>
            </div>
            <ol>
              <li><strong>Find Your Spot:</strong> Choose a comfortable place to sit.  Keep your legs uncrossed, feet flat on the floor, and back gently against the chair.  Ensure good, even lighting on your face.</li>
              <li><strong>Makeup-Free Face:</strong> Take off any face makeup before using the IVC App.</li>
              <li><strong>Relax and Wait:</strong> Sit in this position for five minutes before starting.</li>
              <li><strong>Clear the Way:</strong> Remove any hats, masks, or clothing that might cover your face.</li>
              <li><strong>Position Perfectly:</strong> Align your face within the center of the on-screen guide box and look straight at the camera.</li>
            </ol>
          </section>
          <section id="s13-3">
            <h2 className={classes.sectionHeader}>13.3 Connecting Informed Vital Core Application</h2>
            <div className={classes.clearFix}>
              <div className={classes.bookInfoWrapper}>
                <BookInfoOpenReaderSolid />
              </div>
              <p><strong>Getting started:</strong></p>
            </div>
            <ol>
              <li><strong>Link from Your Provider:</strong> You'll receive a unique link to start the IVC App.</li>
              <li><strong>Open the Link:</strong> Tap or click on this link.  Click on the link within 72 hours, or the link will expire, and you'll have to request a new one from your healthcare provider.</li>
              <li><strong>Browser Launch:</strong> Your default web browser will open.</li>
              <li><strong>Enter Date of Birth:</strong> Input your birth date and hit <strong>CONTINUE</strong>. Make sure you enter your Date of Birth correctly – five incorrect entries will delay authentication and your use of the IVC App.</li>
              <li><strong>Agree to Terms:</strong> Read and agree to the IVC App Terms and Conditions.</li>
              <li><strong>Consent to Record:</strong> Agree to the Consent to Record Vitals.</li>
              <li><strong>Camera Access:</strong> Allow the IVC App to use your device's camera.</li>
              <li><strong>Face Positioning:</strong> Align your face within the guide box on the screen.</li>
              <li><strong>Start Measuring:</strong> Tap <strong>START</strong> to begin measuring your pulse rate.</li>
              <li><strong>IMPORTANT:</strong> Avoid Navigation once measuring starts, don't use the browser's back or forward <span className={classes.noLineWrap}>(<FontAwesomeIcon icon={faAngleLeft} className={classes.arrowIcons} />  <FontAwesomeIcon icon={faAngleRight} className={classes.arrowIcons} />)</span> buttons.</li>
            </ol>
            <div><strong>Measuring Pulse Rate:</strong></div>
            <ol>
              <li><strong>Stay Still:</strong> Remain motionless during measurement.  The IVC App will alert you to any movement or lighting issues.</li>
              <li><strong>Complete the Process:</strong> Wait until the measurement bar fills up. Your pulse rate results will then be displayed.</li>
            </ol>
            <div><strong>Convenience for Next Time:</strong></div>
            <ul>
              <li><strong>One-Time Agreements:</strong> You won't see the Terms and Consent screens again unless there are updates or if you clear your browser storage.</li>
              <li><strong>Skip Instructions Option:</strong> You can choose to skip the operating instructions on future uses. Toggle them back on anytime in the online User Manual.</li>
            </ul>
          </section>
          <section id="s13-4">
            <h2 className={classes.sectionHeader}>13.4 Application Controls/Navigation</h2>
            <p><strong>Browser Buttons:</strong> Use the &lt; and &gt; buttons at the top of the IVC App or your browser's back and forward buttons to move around the app.</p>
            <p><strong>Menu Icon Magic:</strong> Tap the menu icon for more options.</p>
            <div><strong>Menu Options</strong> – Everything at Your Fingertips:</div>
            <ul>
              <li><strong>How to Use:</strong> Toggle on/off the instructions for using the IVC App.</li>
              <li><strong>User Manual:</strong> A comprehensive guide to the IVC App.</li>
              <li><strong>Change Camera Permissions:</strong> Adjust settings to allow camera access.</li>
              <li><strong>FAQ:</strong> Find answers to common questions.</li>
              <li><strong>Terms and Conditions:</strong> Understand the rules and agreements.</li>
              <li><strong>Privacy Policy:</strong> Know how your data is protected.</li>
              <li><strong>Release Notes:</strong> Stay updated with the latest changes and improvements.</li>
            </ul>
          </section>
          <section id="s14-0">
            <h2 className={classes.sectionHeader}>14. Troubleshooting Guide</h2>
          </section>
          <section id="s14-1">
            <h2 className={classes.sectionHeader}>14.1 Common IVC App Errors</h2>
            <div><strong>Problem: IVC App Won't Launch</strong></div>
            <ul>
              <li><strong>Check Your Connection:</strong> Ensure you have a strong cellular or Wi-Fi connection. Weak signals can prevent the IVC App from launching.</li>
              <li><strong>Device Compatibility:</strong> If the problem persists, your device might not be compatible with the IVC App. Consider other ways to measure your pulse rate.</li>
            </ul>
            <div><strong>Problem: Can't Submit Measurements</strong></div>
            <ul>
              <li><strong>Data and Bandwidth:</strong> Make sure you have enough cellular data or Internet bandwidth to submit measurements.</li>
              <li><strong>Alternative Methods:</strong> If issues continue, you might need another method to measure your pulse rate.</li>
            </ul>
            <div><strong>Problem: Pulse Rate Not Measured</strong></div>
            <ul>
              <li><strong>Optimal Conditions:</strong> Sit with even lighting on your face, minimizing head and camera movement. Follow the IVC App's prompts to retry.</li>
              <li><strong>Device Check:</strong> If it still doesn't work, your device may not support the IVC App. Use another method to measure your pulse rate.</li>
            </ul>
          </section>
          <section id="s14-2">
            <h2 className={classes.sectionHeader}>14.2 IVC Application Error Messages</h2>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>IVC App Screen</TableCell>
                  <TableCell>Error Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-dob-mismatch.png"
                      alt="date of birth did not match"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Incorrect Date of Birth</strong></div>
                    <div><strong>What It Means:</strong> The date of birth entered doesn't match the date of birth on file in the patient record with your healthcare provider.   Contact your healthcare provider to verify or correct the date of birth in your patient record.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-notification-link-expired.png"
                      alt="notification link has expired"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Expiration of URL</strong></div>
                    <div><strong>What It Means:</strong> The link included in the SMS message sent by your healthcare provider <strong>will expire 72hours</strong> after you receive it.  If you don't use the IVC App to measure and submit your pulse rate, the link will expire, and you'll need to request a new one from your healthcare provider.  During that 72-hour period, you will receive a reminder SMS message if you haven't used the IVC App.</div>
                    <div><strong>Fix:</strong> Be sure to use the IVC App and submit your results within 72 hours of receiving the link from your healthcare provider.  If the link is expired, contact your healthcare provider, and request a new link.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-15-min-lockout.png"
                      alt="authentication lockout for 15 minutes"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Date of Birth Verification Failure</strong></div>
                    <div><strong>What It Means:</strong> If an incorrect Date of Birth is entered five-times in a row, there will be a <strong>delay period of 15 minutes.</strong> This means that you won't be able to enter the Date of Birth for 15 minutes.  After the expiration of the 15 minute delay period, you'll be able to try again.  If the Date of Birth is incorrect five more times, there will be a <strong>delay period of 60 minutes.</strong>  After the expiration of the 60 minute delay period, you'll be able to try again.  If the Date of Birth is incorrect five more times, you won't be able to use the IVC App.  You will have to contact your healthcare provider to verify your Date of Birth and request a new link.  This is designed to keep your information safe.</div>
                    <div><strong>Fix:</strong> Contact your healthcare provider to verify your Date of Birth and request a new link.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-improve-lighting.png"
                      alt="poor lighting warning"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Poor Lighting Conditions</strong></div>
                    <div><strong>What It Means:</strong> The lighting isn't ideal for the IVC App to work properly.</div>
                    <div><strong>Fix:</strong> Adjust lighting to remove shadows or bright spots for even lighting on your face.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-stay-still.png"
                      alt="hold phone steady warning"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Too Much Movement</strong></div>
                    <div><strong>What It Means:</strong> Your phone or head is moving too much.</div>
                    <div><strong>Fix:</strong> Steady your phone and head. Make sure your face is centered in the guide box.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-keep-face-in-guide-box.png"
                      alt="hold phone steady warning"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Face Outside Guide Box</strong></div>
                    <div><strong>What It Means:</strong> Your face has moved out of the required position.</div>
                    <div><strong>Fix:</strong> Realign your face to the center, focusing on the + sign in the guide box.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-unable-to-measure.png"
                      alt="unable to measure warning"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Unable to Measure Vitals</strong></div>
                    <div><strong>What It Means:</strong> A pulse rate was unable to be measured.</div>
                    <div><strong>Fix:</strong> Attempt the measurement again. You can try to measure your pulse rate three times during each measurement session before you receive a Continuous Error.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-continuous.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Continuous Error</strong></div>
                    <div><strong>What It Means:</strong> The IVC App has tried to measure a pulse rate three times in a row.   The device used may not be compatible with the IVC App or conditions are such that pulse rate cannot be measured.</div>
                    <div><strong>Fix:</strong> Consider using a different device that is compatible with the IVC App.  Consider using an alternative means to measure your pulse rate.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-lighting-and-movement.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Lighting and Movement Error</strong></div>
                    <div><strong>What It Means:</strong> Lighting is poor (too dark or bright) and there is too much movement during measurement session).</div>
                    <div><strong>Fix:</strong> Restart your session. Improve your lighting and stay still while measuring.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-inactivity.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Inactivity</strong></div>
                    <div><strong>What It Means:</strong> The IVC App will automatically close if there has been no interaction with the IVC App for more than five minutes.</div>
                    <div><strong>Fix:</strong> Measure your pulse rate within five minutes once you open the IVC App.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-unable-to-download.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Lost Internet Connection</strong></div>
                    <div><strong>What It Means:</strong> You need to have an Internet connection to download the IVC App. Your device is currently offline.</div>
                    <div><strong>Fix:</strong> Check and restore your internet connection.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-lost-internet.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Lost Internet Connection</strong></div>
                    <div><strong>What It Means:</strong> You need to have an Internet connection to submit your pulse rate result to your healthcare provider. Your device is currently offline.</div>
                    <div><strong>Fix:</strong> Check and restore your internet connection.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-general.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Failed Submission Screen</strong></div>
                    <div><strong>What It Might Be:</strong> Loss of server connection when attempting to submit your vitals two times in a row.</div>
                    <div><strong>Fix:</strong> Attempt to submit your vitals one more time or try again later using the URL in your text message.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-continuous-submission.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Continuous Error (Failed Submission)</strong></div>
                    <div><strong>What It Might Be:</strong> Loss of server connection when attempting to submit your vitals after three attempts.</div>
                    <div><strong>Fix:</strong> Try again later using the URL in your text message.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-camera-permissions.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Camera Access Denied</strong></div>
                    <div><strong>What It Means:</strong> The IVC App can't use your device's camera.</div>
                    <div><strong>Fix:</strong> Change your device settings to allow camera access for the IVC App.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/error-device.png"
                      alt="continuous error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Incompatible Device For Measurement</strong></div>
                    <div><strong>What It Means:</strong> The IVC App timed out because your device isn't compatible.</div>
                    <div><strong>Fix:</strong> Consider using a different device that is compatible with the IVC App.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/ivc-dob-auth-server-error.png"
                      alt="authentication server error"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Something Went Wrong</strong></div>
                    <div><strong>What It Means:</strong> The IVC App was not able to connect with the backend servers to confirm your date of birth.</div>
                    <div><strong>Fix:</strong> Try again later.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/ivc-session-conflict.png"
                      alt="session conflict"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: The IVC App can only be run in one browser window. The IVC App was opened in one browser window and then again in a second browser window. The error will appear in the first browser window.</strong></div>
                    <div><strong>What It Means:</strong> Close the browser window with the error screen and use the IVC App as instructed.</div>
                    <div><strong>Fix:</strong> Try again later.</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <img
                      src="/img/ivc-invalid-page.png"
                      alt="invalid page"
                      className={classes.tableImage}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.marginBottom20}><strong>Error: Invalid Page Screen.</strong></div>
                    <div><strong>What It Means:</strong> You have attempted to navigate back into the IVC App after submitting a measured result, attempted to reload a screen during an active measurement session, or used the browser history to return to a screen in the App.</div>
                    <div><strong>Fix:</strong> Restart the IVC App by tapping on the original URL received.</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </section>
          <section id="s15-0">
            <h2 className={classes.sectionHeader}>15. Customer Support and Contact Information</h2>
            <p>Please email any comments, concerns, or questions to: <a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com</a>  or contact 480.382.3179.</p>
          </section>
          <section id="s16-0">
            <h2 className={classes.sectionHeader}>16. Manufacturer</h2>
            <div>Mindset Medical</div>
            <div>12439 North 32 Street</div>
            <div>Phoenix, AZ 85032 USA</div>
          </section>
        </div>
      </div>
    );
  }
}

CoreVitalUserManual.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalUserManual);
