import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router';

import AppBar from '../components/app-bar';
import { colors, fontSizing } from '../lib/styles';

const styles = {
  coreVitalsVersion: {
    fontSize: fontSizing.small,
    marginTop: 5,
  },
  coreVitalsVersionHeader: {
    fontWeight: 'bold',
  },
  pageContentWrapper: {
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 500,
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
  },
  cautionStatementWrapper: {
    marginBottom: 20,
    marginTop: 20,
  },
  link: {
    background: 'white',
    borderBottom: '1px solid #f5f5f5',
    color: 'black',
    fontSize: fontSizing.body,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingTop: 15,
    textDecoration: 'none',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class CoreVitalUserManualMenu extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
        />
        <div className={classes.pageContentWrapper}>
          <div className={classes.cautionStatementWrapper}>
          </div>
          <div className={classes.linksContainer}>
            <Link
              className={classes.link}
              to="/core-vital-how-to-use"
            >
              How to Use Informed Vital Core
            </Link>
            <Link
              className={classes.link}
              to="/core-vital-user-manual"
            >
              User Manual
            </Link>
            <Link
              className={classes.link}
              to="/core-vital-turn-on-permissions"
            >
              Change Camera Permissions
            </Link>
            <Link
              className={classes.link}
              to="/core-vital-faq"
            >
              FAQ
            </Link>
            <Link
              className={classes.link}
              to="/core-vital-terms-and-conditions"
            >
              Terms and Conditions
            </Link>
            <Link
              className={classes.link}
              to="/core-vital-privacy-policy"
            >
              Privacy Policy
            </Link>
            <Link
              className={classes.link}
              to="/core-vital-release-notes"
            >
              Release Notes
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

CoreVitalUserManualMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalUserManualMenu);
