import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { forward } from './navigation';
import GeneralLayout from '../components/general-layout';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30,
  },
  button: {
    height: 50,
    borderRadius: 25,
    borderStyle: 'none',
    width: '100%',
    color: 'white',
    fontSize: fontSizing.body,
    maxWidth: desktopMaxWidth,
  },
  logo: {
    maxWidth: 400,
    margin: '30px auto',
    width: '100%',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  pageContainer: {
    flexGrow: 1,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
    '& p': {
      textAlign: 'center',
    },
  },
  h1: {
    fontSize: 24,
    marginTop: 50,
    textAlign: 'center',
  },
  image: {
    maxWidth: 500,
    width: '100%',
  },
  imageRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  instructionsColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingRight: 10,
  },
  instructionsColumnRight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: 10,
  },
  instructionsRow: {
    display: 'flex',
    marginBottom: 30,
  },
  instructionsTextGreen: {
    color: colors.green,
    fontStyle: 'italic',
    padding: 10,
    textAlign: 'center',
    fontSize: fontSizing.body,
  },
  instructionsTextRed: {
    color: colors.red,
    fontStyle: 'italic',
    padding: 10,
    textAlign: 'center',
    fontSize: fontSizing.body,
  },
  bulletpoint: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
}

const Instructions = (props) => {
  const { classes, router } = props;

  const handleClick = () => {
    forward(router, props.location.query);
  };

  return (
    <GeneralLayout title="Instructions" buttonText="START" buttonOnClick={handleClick}>
      <div className={classes.pageContent}>
        <h1 className={classes.h1}>Instructions</h1>
        <Typography style={styles.bulletpoint}>
          Place your device on a stand and on a firm,
          stable surface so that it is not moving while measuring your vitals.
          If you do not have a stand, place your device up against something
          with a solid backing so that it doesn't move.
        </Typography>
        <hr/>
        <Typography style={styles.bulletpoint}>Fit face in guide area</Typography>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionsColumnLeft}>
            <img className={classes.image} src="/img/vt_good.png" alt="good face placement" />
            <div className={classes.instructionsTextGreen}>Good</div>
          </div>
          <div className={classes.instructionsColumnRight}>
            <img className={classes.image} src="/img/vt_bad_toosmall.png" alt="face too small" />
            <div className={classes.instructionsTextRed}>Don't try to fit your hair</div>
          </div>
        </div>
        <Typography style={styles.bulletpoint}>Keep looking at the camera</Typography>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionsColumnLeft}>
            <img className={classes.image} src="/img/vt_good.png" alt="good face placement" />
            <div className={classes.instructionsTextGreen}>Good</div>
          </div>
          <div className={classes.instructionsColumnRight}>
            <img className={classes.image} src="/img/vt_bad.png" alt="face looking away" />
            <div className={classes.instructionsTextRed}>Don't look away</div>
          </div>
        </div>
        <Typography style={styles.bulletpoint}>Stay still</Typography>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionsColumnLeft}>
            <img className={classes.image} src="/img/vt_good.png" alt="good face placement" />
            <div className={classes.instructionsTextGreen}>Good</div>
          </div>
          <div className={classes.instructionsColumnRight}>
            <img className={classes.image} src="/img/vt_bad_motion.png" alt="face moving" />
            <div className={classes.instructionsTextRed}>Don't move</div>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};

Instructions.propTypes = {
  router: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(Instructions));
