import { createMuiTheme } from '@material-ui/core/styles';

import { colors } from './styles';

export const muiVitalTracNextTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor,
    },
  },
  typography: {
    body1: {
      fontSize: 18,
      fontFamily: "Nexa, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
    },
    h1: {
      fontSize: 44,
    },
    h2: {
      fontSize: 33,
    },
    h3: {
      fontSize: 27,
    },
  },
});
