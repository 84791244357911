import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { KeypadDate } from 'common-ui';

import Page from './page';
import buildTrackFromBundledEvents from '../lib/build-track-from-bundled-events';
import {
  loginShadow,
  addRequestingClinicNumber,
  setNotificationAuthLogoutRoute,
} from '../state/user';
import { clearPros, createProData } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import HiddenContent from '../components/hidden-content';
import { apiFetch } from '../lib/fetch';
import config from '../config';
import { throttledReset } from '../initializers/activity';
import { createVitalsRunnerWebWorker } from '../lib/vitals-runner';
import Logo from '../components/logo';
import GeneralLayout from '../components/general-layout';

const acceptableProTypes = [
  'VITAL-TRAC',
  'BUNDLED_EVENT',
];

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '350px',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.25rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
    textAlign: 'center',
    width: '100%',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    alignSelf: 'center',
    color: colors.errorRed,
    margin: 15,
    fontWeight: 800,
    fontSize: fontSizing.small,
    textAlign: 'center',
  },
};
const defaultMessage = 'This link may no longer be used. Please close the browser window.';
class ShadowConfirmation extends Page {
  constructor(props) {
    super(props);

    this.state = {
      dob: '',
      error: '',
      clinic_id: null,
      clinicNumber: null,
      clinic_logo: '',
      notificationType: '',
      notificationExpired: false,
      additionalDobAuthErrorMessage: '',
      showDobAuthErrorMessage: false,
    };

    this.props.setNotificationAuthLogoutRoute(window.location.pathname);

    this.activityTimer = setInterval(throttledReset, 10000);
  }

  async componentWillMount() {
    const clinic = await apiFetch(`/notifications_clinic/${this.props.params.notificationId}`);
    if (!acceptableProTypes.includes(clinic.notification_type)) window.location.replace(`${config.PATIENT_APP_URL}/notifications/${this.props.params.notificationId}`);
    // login if dob, then set tracks and skip notificaiton page
    const { dob } = this.props.location.query;
    if (dob) {
      const login = await this.props.loginShadow(this.props.params.notificationId, dob);
      this.props.addRequestingClinicNumber(clinic.phone);
      const track = buildTrackFromBundledEvents(login.meta.bundled_events, this.props.params.notificationId, dob);
      this.setRailroad(track);
    }
    this.setState({
      clinic_id: clinic.id,
      clinic_name: clinic.name,
      clinicNumber: clinic.phone,
      notificationType: clinic.notification_type,
      notificationExpired: clinic.notificationExpired || false,
    });
    document.title = clinic.name;
    const favicon = document.getElementById('favicon');
    favicon.href = 'img/favicon.ico';
    const logo = `img/vital-trac.png`;
    this.setState({
      clinic_logo: logo,
    });
    createVitalsRunnerWebWorker();
  }

  componentWillUnmount() {
    clearInterval(this.activityTimer);
  }

  handleChange = (dob) => {
    this.setState({ dob });
  }

  onSubmission = () => {
    this.setState({ error: '', additionalDobAuthErrorMessage: '', showDobAuthErrorMessage: false });
    this.props.clearPros();
    return this.props.loginShadow(this.props.params.notificationId, this.state.dob)
      .then((data) => {
        this.props.createProData(data.notification_type, {}, data.meta.pro_submission_id || data.meta.video_broadcast_id);
        this.props.addRequestingClinicNumber(this.state.clinicNumber);
        this.props.router.push({ pathname: '/notification-intro', state: { id: data.meta.pro_submission_id || data.meta.video_broadcast_id, type: data.notification_type, clinic_id: this.state.clinic_id, dob: this.state.dob } });
      })
      .catch((e) => {
        const { lang } = this.props.location.query;
        let message = get(e, 'content.message') || get(e, 'content.error') || defaultMessage;
        if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
          const additionalDobAuthErrorMessage = e.content.failedLoginAttemptMessage;
          return this.setState({ dob: '', additionalDobAuthErrorMessage, showDobAuthErrorMessage: true });
        }
        if (e.status === 403) {
          return this.props.router.push('/login?originalPath=/notifications&msg=notification');
        }
        if (e.status === 404) {
          message = 'THE DATE OF BIRTH ENTERED DOES NOT MATCH YOUR PATIENT RECORD.';
          if (lang === 'es') {
            message = 'LA FECHA DE NACIMIENTO INTRODUCIDA NO COINCIDE CON SU REGISTRO DE PACIENTE.';
          }
        } else if (!navigator.onLine) {
          message = 'Lost Internet connection.  Please try again when you’re connected to the Internet.';
        } else if (e.status !== 410) {
          message = 'Something went wrong. Please try again later.';
        }
        this.setState({
          dob: '',
          error: message,
        });
      });
  }

  render() {
    const { notificationExpired } = this.state;
    const { lang } = this.props.location.query;

    const details = {
      title: 'Please confirm your date of birth',
      buttonLabel: 'Continue',
    };

    if (lang === 'es') {
      details.title = 'Por favor confirme su fecha de nacimiento:';
      details.buttonLabel = 'Seguir';
    }

    const { title, buttonLabel } = details;

    return (
      <GeneralLayout
        hidden
        buttonText={buttonLabel}
        buttonOnClick={this.onSubmission}
      >
        <Logo />
        {notificationExpired ? (
          <div>
            <p style={{ ...styles.error, fontWeight: 'normal' }}>The notification link has expired.</p>
            <p style={styles.error}><strong>Please check your messages for a more recent link or contact your healthcare provider.</strong></p>
            <p style={{ ...styles.error, fontWeight: 'normal' }}>You may now close this window</p>
          </div>
        ) : (
          <div style={styles.container}>
            <div style={styles.titleGroup}>
              <div style={styles.title}>{title}</div>
            </div>
            <KeypadDate onChange={this.handleChange} value={this.state.dob} style={{ marginLeft: 0}} />
            <HiddenContent hidden={!this.state.error}>
              <div style={styles.error}>
                {this.state.error}
              </div>
            </HiddenContent>
            <HiddenContent hidden={!this.state.showDobAuthErrorMessage}>
              <div style={{ ...styles.error, fontWeight: 'normal' }}>
                {`The date of birth has been entered incorrectly five times in a row.${this.state.additionalDobAuthErrorMessage ? ` ${this.state.additionalDobAuthErrorMessage}` : ''}`}
              </div>
              <p style={styles.error}>
                <strong>Please contact your healthcare provider to verify your date of birth on file.</strong>
              </p>
              <div style={styles.error}>
                <strong>You may now close this window.</strong>
              </div>
            </HiddenContent>
          </div>
        )}
      </GeneralLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms } = state;
  return { proForms };
}

ShadowConfirmation.propTypes = {
  clearPros: PropTypes.func.isRequired,
  createProData: PropTypes.func.isRequired,
  proForms: PropTypes.object.isRequired,
  loginShadow: PropTypes.func.isRequired,
  setNotificationAuthLogoutRoute: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  addRequestingClinicNumber,
  clearPros,
  createProData,
  loginShadow,
  setNotificationAuthLogoutRoute,
})(ShadowConfirmation);
