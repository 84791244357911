import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';

import AppBar from './app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    height: 50,
    borderRadius: 25,
    borderStyle: 'none',
    width: '100%',
    color: 'white',
    fontSize: fontSizing.body,
    maxWidth: desktopMaxWidth,
    '&:disabled': {
      backgroundColor: '#d8d8d8',
    },
  },
  logo: {
    maxWidth: 400,
    margin: '30px auto',
    width: '100%',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
  },
  pageContainer: {
    flexGrow: 1,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
  },
}

const GeneralLayout = (props) => {
  const {
    children,
    classes,
    title,
    buttonText,
    buttonOnClick,
    buttonDisabled = false,
    buttonColor = colors.red,
    hidden = false,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.pageContainer} style={{ paddingTop: hidden ? '50px' : '0px' }}>
      {!hidden && (
          <AppBar
            backButtonOnClick={() => router.goBack()}
            headerNode={title}
            rightNode={(
              <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          />
        )}
        <div className={classes.pageContent}>
          {children}
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <button
          type="button"
          onClick={buttonOnClick}
          className={classes.button}
          role="link"
          disabled={buttonDisabled}
          style={{ backgroundColor: buttonColor }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default withStyles(styles)(GeneralLayout);
